import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { bannerSlider } from "../constraints/constants";

const HomeBanner = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const getData = async () => {
    const { status, data } = await apiPost(apiPath.getBanner, {
      type: "home_top",
    });
    if (status == 200) {
      if (data?.success) {
        setData(data?.results?.banners);
      }
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="home-banner-sec">
      <Slider {...settings}>
        {data?.length > 0
          ? data?.map((item) => {
              return (
                <div>
                  <img
                    src={process.env.REACT_APP_API_BASE_URL + item?.banner_path}
                    style={{ width: "100%" }}
                    alt=""
                  />
                </div>
              );
            })
          : bannerSlider?.map((item) => {
              return (
                <div
                  onClick={() => {
                    navigate(
                      `/casino?category=${item?.category}&type=${item?.type}`
                    );
                  }}
                >
                  <img src={item?.img} style={{ width: "100%" }} alt="" />
                </div>
              );
            })}
      </Slider>
    </div>
  );
};

export default HomeBanner;
