import React, { useContext } from "react";
import AuthProvider from "../context/AuthContext";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

const GameCard = ({ item, cartType, res }) => {
  let { launchEGTCasino, user, launchCasino, amounutRefresh } =
    useContext(AuthProvider);
  const navigate = useNavigate();
  return (
    <div
      className="game-card position-relative"
      onClick={() => {
        if (!isEmpty(user)) {
          if (!isEmpty(res?.gameid)) {
            amounutRefresh();
            document.getElementById("loading").style.display = "flex";
            setTimeout(() => {
              launchEGTCasino({
                platForm: res?.platForm,
                gameType: res?.gameType,
                casinoType: res?.casinoType,
                gameid: res?.gameid,
              });
            }, 800);
          } else {
            if (
              res?.platForm !== "" &&
              res?.gameType !== "" &&
              res?.casinoType !== ""
            ) {
              if (!isEmpty(res?.table)) {
                document.getElementById("loading").style.display = "flex";
                amounutRefresh();
                setTimeout(() => {
                  launchCasino({
                    platForm: res?.platForm,
                    gameType: res?.gameType,
                    casinoType: res?.casinoType,
                    table: res?.table,
                  });
                }, 800);
              } else {
                document.getElementById("loading").style.display = "flex";
                amounutRefresh();
                setTimeout(() => {
                  launchCasino({
                    platForm: res?.platForm,
                    gameType: res?.gameType,
                    casinoType: res?.casinoType,
                  });
                }, 800);
              }
            }
          }
        } else {
          navigate("/login");
        }
      }}
    >
      {!isEmpty(res?.table) && (
        <div className="table-data">
          <span className="">Table{res?.table}</span>
        </div>
      )}
      {/* <img loading="lazy" src={res?.img} alt="" /> */}
      <LazyLoadImage
        alt={res?.img}
        // height={image.height}
        effect="opacity"
        src={res?.img} // use normal <img> attributes as props
        // width={image.width}
      />
    </div>
  );
};

export default GameCard;
