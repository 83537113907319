import React, { useContext, useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import SidebarLayout from "../components/shared/SidebarLayout";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import obj from "../Utils/helpers";
import objConstant from "../Utils/constants";
import AuthContext from "../context/AuthContext";
import { compact, isEmpty } from "lodash";
import { IoIosArrowForward } from "react-icons/io";
const AccountStatement = () => {
  const { userCoins, user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [listing, setListing] = useState([]);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const handel = () => {
    setFilter({
      ...filter,
      page: filter?.page + 1,
    });
    getData(
      {
        ...filter,
        page: filter?.page + 1,
      },
      "add"
    );
  };
  const getData = async (obj = filter, type) => {
    const { status, data } = await apiGet(apiPath.accountStatement, obj);
    if (status == 200) {
      if (data?.success) {
        setData(data?.results);
        if (type == "add") {
          setListing([...listing, ...data?.results?.data]);
        } else {
          setListing(data?.results?.data);
        }
      }
    }
  };
  useEffect(() => {
    if (!isEmpty(user)) {
      getData();
    }
  }, [user]);

  const getDataCasino = async (obj = filter, type) => {
    const { status, data } = await apiGet(apiPath.casionStatement, obj);
    if (status == 200) {
      if (data?.success) {
        setData(data?.results);
        if (type == "add") {
          setListing([...listing, ...data?.results?.data]);
        } else {
          setListing(data?.results?.data);
        }
      }
    }
  };
  return (
    <SidebarLayout heading={"Account Statement"}>
      <div className="p-3 pt-4 inner-sidebar-content d-flex flex-column ">
        <div
          className="balance-sec"
          style={{
            borderRadius: ".75rem",
            paddingTop: "1rem",
            paddingBottom: ".7rem",
          }}
        >
          <h5
            style={{ fontSize: "1rem", fontWeight: "700" }}
            className="text-white mb-0"
          >
            Your Balances
          </h5>
          <h6
            style={{
              fontSize: "1.5714285714285714rem",
              display: "flex",
              alignItems: "end",
            }}
            className="text-white mb-0"
          >
            {" "}
            <span
              style={{ fontSize: ".7142857142857143rem", marginRight: "7px" }}
            >
              PBU
            </span>{" "}
            {userCoins?.balance?.toFixed(2)}
          </h6>
        </div>
        {listing?.length > 0
          ? listing?.map((res) => {
              return (
                <div className="balance-deposit-sec mt-3">
                  <Table
                    style={{ borderRadius: ".5rem" }}
                    bordered
                    className=" overflow-hidden bg-white"
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            fontWeight: "400",
                            fontSize: ".8571428571428571rem",
                            padding: ".3rem .5rem",
                          }}
                          colSpan="4"
                        >
                          {obj.dateFormat(res?.createdAt || res?.timeInserted)}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {!isEmpty(res?.casinoBetId) ? (
                          <>
                            <td
                              style={{
                                padding: "3px 5px",
                                fontSize: ".7142857142857143rem",
                              }}
                              width="50%"
                            >
                              Casino Bet Id
                              <strong
                                style={{ fontSize: "14px" }}
                                className="d-block"
                              >
                                {res?.casinoBetId}
                              </strong>
                            </td>
                            <td
                              style={{
                                padding: "3px 5px",
                                fontSize: ".7142857142857143rem",
                              }}
                              width="50%"
                            >
                              Client
                              <strong
                                style={{ fontSize: "14px" }}
                                className="d-block"
                              >
                                {res?.clientName}
                              </strong>
                            </td>
                          </>
                        ) : (
                          <>
                            <td
                              style={{
                                padding: "3px 5px",
                                fontSize: ".7142857142857143rem",
                              }}
                              width="50%"
                            >
                              {res?.transactionType === "credit"
                                ? "Debits"
                                : "Credits"}{" "}
                              <strong
                                style={{ fontSize: "14px" }}
                                className={`d-block ${
                                  res?.transactionType === "credit"
                                    ? "text-success"
                                    : "text-danger"
                                }`}
                              >
                                {res?.transactionType === "credit"
                                  ? res?.amount?.toFixed(2)
                                  : `(${res?.amount?.toFixed(2)})`}
                              </strong>
                            </td>
                            <td
                              style={{
                                padding: "3px 5px",
                                fontSize: ".7142857142857143rem",
                              }}
                              width="50%"
                            >
                              Balance{" "}
                              <strong
                                style={{ fontSize: "14px" }}
                                className="d-block"
                              >
                                {res?.newBalance[0]?.toFixed(2)}
                              </strong>
                            </td>
                          </>
                        )}
                      </tr>
                      <tr>
                        <td
                          style={{ fontSize: "14px" }}
                          width="50%"
                          colSpan="4"
                        >
                          {!isEmpty(res?.casinoBetId) ? "Game Info" : "Remark"}
                          <strong className="d-block">
                            {!isEmpty(res?.casinoBetId)
                              ? `${res?.platform} - ${res?.gameInfo}`
                              : compact([
                                  objConstant.betCheckObj[res?.eventType],
                                  res?.betFaireType,
                                  res?.gameName,
                                  res?.eventId,
                                  res?.runnerName,
                                ]).join(" / ")}
                          </strong>
                        </td>
                      </tr>
                      {!isEmpty(res?.casinoBetId) ? (
                        <tr>
                          <td style={{ fontSize: "14px" }} width="50%">
                            Stake
                            <strong className="d-block">
                              {res?.betAmount}
                            </strong>
                          </td>
                          <td style={{ fontSize: "14px" }} width="50%">
                            Profit / Loss
                            <strong className="d-block">
                              {res?.realCutAmount}
                            </strong>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td width="50%" colSpan="4">
                            {res?.createdByData?.username}{" "}
                            <span className="master-pass"></span>
                            {!isEmpty(res?.userData?.firstName) && (
                              <span className="master-pass">
                                {res?.userData?.firstName ||
                                  res?.userData?.lastName}
                              </span>
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              );
            })
          : "No Record Found"}
        {data?.hasNextPage && (
          <div className="w-100 d-flex justify-content-center align-items-center">
            <Button
              onClick={handel}
              style={{ background: "black", border: "none" }}
            >
              Show More <IoIosArrowForward />
            </Button>
          </div>
        )}
      </div>
    </SidebarLayout>
  );
};

export default AccountStatement;
