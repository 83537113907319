import React, { useContext, useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import AuthContext from "../context/AuthContext";
import { RxCross2 } from "react-icons/rx";
import { Button } from "react-bootstrap";
import { BiSolidRightArrow } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import obj from "../Utils/constants";
import secureLocalStorage from "react-secure-storage";
import StackSetting from "./StackSetting";
import { AiFillSetting } from "react-icons/ai";
import moment from "moment";
import { apiGet, apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import BetSlipContext from "../context/BetSlipContext";
import { isEmpty } from "lodash";
import { TiArrowSortedUp } from "react-icons/ti";
import BetSlipParlyInner from "./BetSlipParlyInner";
import loading from "../assets/images/loading_bet.webp";
const BetSlipParly = () => {
  const numberArrayCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "00", "img"];
  const numberArray = [10, 100, 200, 500];
  const {
    setShowParlyBet,
    setParlyBet,
    parlyBet,
    profileData,
    amounutRefresh,
    setMessage,
    message,
  } = useContext(AuthContext);
  const { setBetPlace, handelError, betLoader, setBetLoader, resetLayout } =
    useContext(BetSlipContext);
  const [stake, setStake] = useState("");
  const [showStackSetting, setShowStackSetting] = useState(false);
  const [showDial, setShowDial] = useState(false);
  const [totalOdds, setTotalOdds] = useState("");
  const [loader, setLoader] = useState(false);
  const handleStackSetting = () => {
    setShowStackSetting(!showStackSetting);
  };
  const removeSlip = (id) => {
    setParlyBet((current) => {
      if (current?.length == 1) {
        secureLocalStorage.setItem("parlyBet", []);
      } else {
        secureLocalStorage.setItem(
          "parlyBet",
          current?.filter((res) => {
            return res?.newEventId !== id;
          })
        );
      }
      return current?.filter((res) => {
        return res?.newEventId !== id;
      });
    });
    if (parlyBet?.length == 1) {
      setShowParlyBet(false);
    }
  };

  const handelNumberChange = (item) => {
    setStake(item);
  };

  const handelDialPad = (item) => {
    let str = stake?.toString() + item?.toString();
    if (str?.length > 6) {
    } else {
      setStake(Number(str));
    }
  };

  useEffect(() => {
    if (parlyBet?.length > 0) {
      setTotalOdds(
        parlyBet
          ?.filter((res) => !res?.closed)
          ?.reduce(
            (accumulator, currentValue) =>
              accumulator * currentValue?.sportsBookSelectionObject?.odds,
            1
          )
          ?.toFixed(2)
      );
    }
  }, [parlyBet]);

  useEffect(() => {
    let interval = setInterval(() => {
      getOdds(parlyBet);
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const getOdds = async (parlyBet) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getPremiumFancy +
        `?events=${parlyBet
          ?.map((res) => {
            return res?.betfairEventId;
          })
          .toString()
          .trim()}&multi=true`
    );
    if (status === 200) {
      if (response_users.success) {
        setParlyBet((current) => {
          let result = current?.map((item) => {
            let obj = response_users?.results.find(
              (res) => res?.eventId == item?.eventId && res?.id == item?.id
            );
            let condition =
              obj?.marketStatus == 9 ||
              obj?.marketStatus == 2 ||
              obj?.marketStatus == 3 ||
              obj?.marketStatus == 4;
            if (
              obj?.eventId == item?.eventId &&
              obj?.id == item?.id &&
              obj?.sportsBookSelection?.length > 0
            ) {
              return {
                id: obj?.id,
                eventId: obj?.eventId,
                apisite: obj?.apisite,
                apiSiteMarketId: obj?.apiSiteMarketId,
                eventType: obj?.eventType,
                betfairEventId: obj?.betfairEventId,
                marketName: obj?.marketName,
                numberOfWinner: obj?.numberOfWinner,
                numberOfActiveRunners: obj?.numberOfActiveRunners,
                marketStatus: obj?.marketStatus,
                isExpand: obj?.isExpand,
                closeSite: obj?.closeSite,
                bookMode: obj?.bookMode,
                apiSiteStatus: obj?.apiSiteStatus,
                apiSiteSpecifier: obj?.apiSiteSpecifier,
                updateDate: obj?.updateDate,
                min: obj?.min,
                max: obj?.max,
                sportsBookSelection: obj?.sportsBookSelection,
                oldSportsBookSelection: obj?.oldSportsBookSelection,
                selectionTs: obj?.selectionTs,
                odd_ts: obj?.odd_ts,
                ts: obj?.ts,
                check: obj?.check,
                sportsBookSelectionObject: obj?.sportsBookSelection?.find(
                  (pop) => {
                    return item?.sportsBookSelectionObject?.id == pop?.id;
                  }
                ),
                selectObj: obj?.selectObj,
                newEventId: item?.newEventId,
                eventName: item?.eventName,
                marketId: item?.marketId,
                closed:
                  obj?.apiSiteStatus !== "ACTIVE" || condition ? true : false,
              };
            } else {
              return { ...item, closed: true };
            }
          });
          secureLocalStorage.setItem("parlyBet", result);
          return result;
        });
      }
    }
  };

  const clear = () => {
    let str = stake?.toString();
    if (str?.length == 1) {
      setStake("");
    } else {
      setStake(str.substring(0, str.length - 1));
    }
  };

  const isDisabled = () => {
    if (loader) {
      return true;
    } else {
      if (stake > 0) {
        return false;
      } else {
        return true;
      }
    }
  };

  const placeBet = async () => {
    if (parlyBet?.length >= 2) {
      setBetLoader(true);
      let filtered = parlyBet?.filter((res) => res?.closed);
      if (filtered?.length > 0) {
        handelError(
          {
            message: `${filtered?.map((res) => {
              return res?.eventId;
            })}`,
          },
          "sportBook"
        );
        resetLayout();
      } else {
        const date = moment(new Date()).format("YYYY-MM-DD, h:mm:ss");
        let resultArray = parlyBet?.map((res) => {
          return {
            eventId: res?.newEventId,
            marketId: res?.marketId,
            fancySelectionId: res?.sportsBookSelectionObject?.id,
            selectionId: res?.id,
            betPlaceTime: date,
            fancyName: res?.marketName,
            bhav: res?.sportsBookSelectionObject?.odds,
            betType: "back",
            runnerName: res?.sportsBookSelectionObject?.selectionName,
            apiSiteSelectionId:
              res?.sportsBookSelectionObject?.apiSiteSelectionId,
            betPlaceType: "premiumFancy",
          };
        });
        let obj = {
          betPlaceTime: date,
          overallOdds: totalOdds,
          totalAmount: stake,
          bets: resultArray,
        };
        const { status, data: response_users } = await apiPost(
          apiPath.multiEventPlaceBet,
          obj
        );
        if (status === 200) {
          if (response_users?.success) {
            setBetPlace({
              isPlaced: true,
              message: response_users?.message,
              totalOdds: totalOdds,
              stake: stake,
              status: true,
              betType: "sportBookParly",
              type: "matched",
            });

            setMessage({
              ...message,
              message: response_users?.message,
              totalOdds: totalOdds,
              stake: stake,
              status: true,
              betType: "sportBookParly",
              errorType: "sportBookParly",
            });
            amounutRefresh();
            resetLayout();
          } else {
            resetLayout();
            // resetLayout();
            handelError(response_users, "sportBook");
          }
        } else {
          resetLayout();
          // resetLayout();
          handelError(response_users, "sportBook");
        }
      }
    } else {
      handelError({ message: "Please select min 2 parly bet" }, "sportBook");
    }
  };

  let height = document.getElementById("parlay-footer")?.offsetHeight || 200;
  console.log(parlyBet, "parlyBet");
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        if (!betLoader) {
          setShowParlyBet(false);
        }
      }}
    >
      <div
        disabled={betLoader}
        className="market-depth-modal slide-up"
        style={{
          background: "white",
          height: `calc(100vh - 70px)`,
          overflow: `auto`,
        }}
      >
        <div
          style={{ background: "white", color: "black" }}
          className="market-title"
        >
          <h4 className="text-black">Bet Slip</h4>
          <Button
            onClick={() => setShowParlyBet(false)}
            className="border-0 text-black position-absolute end-0 top-0 pt-1 fs-4 bg-transparent border-0"
          >
            <RxCross2 />
          </Button>
        </div>

        <div class="divide-y divide-black-300 position-relative">
          <div
            style={{
              height:
                parlyBet?.length > 4 ? `calc(100vh - ${height}px)` : "100%",
              overflowY: "scroll",
              position: "relative",
              zIndex: "9",
            }}
          >
            {parlyBet?.map((res, index) => {
              let oldObject = res?.oldSportsBookSelection?.find((obj) => {
                return obj?.id == res?.sportsBookSelectionObject?.id;
              });
              return (
                <BetSlipParlyInner
                  oldObject={oldObject}
                  index={index}
                  res={res}
                  removeSlip={removeSlip}
                />
              );
            })}
          </div>
          <div
            id="parlay-footer"
            style={{
              position: "fixed",
              bottom: "0",
              width: "100%",
              zIndex: "999999",
              background: "white",
            }}
          >
            <div
              style={{
                borderTop: "1px solid #D4E0E5",
                borderBottom: "1px solid #D4E0E5",
              }}
              class="px-2"
            >
              <div data-v-4ac8cb63="" class="py-2 flex justify-between">
                <div class="flex justify-center items-center">
                  <span
                    style={{
                      fontWeight: 700,
                      marginRight: "5px",
                      fontSize: ".9285714285714286rem",
                    }}
                    class="mr-2 text-13 text-black font-bold"
                  >
                    Total Odds
                  </span>
                  <span
                    style={{
                      marginRight: "8px",
                      color: "#c69700",
                      fontWeight: 700,
                    }}
                    class="mr-2 text-13"
                  >
                    @
                    {parlyBet?.length > 0
                      ? totalOdds == 1
                        ? 0
                        : totalOdds
                      : 0}
                  </span>
                  <IoIosArrowForward color="green" />
                  <span
                    style={{ color: "green" }}
                    class="text-10 text-parlay-up font-bold"
                  >
                    {totalOdds > 0 && stake > 0
                      ? ((totalOdds - 1) * stake)?.toFixed(2)
                      : 0}
                  </span>
                </div>
                <div
                  data-v-4ac8cb63=""
                  class="flex justify-center items-center"
                >
                  <span
                    style={{
                      fontWeight: 700,
                      marginRight: "7px",
                      fontSize: ".9285714285714286rem",
                    }}
                    class="text-13 text-black font-bold"
                  >
                    Stake
                  </span>
                  <input
                    type="number"
                    inputmode="none"
                    style={{
                      width: "80px",
                      borderRadius: "3px",
                      border: "1px solid black",
                      display: "flex",
                      justifyContent: "center",
                      textAlign: "center",
                      fontWeight: 700,
                    }}
                    onKeyDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      setShowDial(true);
                      e.target.blur();
                    }}
                    value={stake}
                  />
                </div>
              </div>

              {showDial && (
                <>
                  <div className="fix-bet-price d-flex align-items-center">
                    <div className="fix-bet-btn d-flex align-items-center w-100">
                      {(profileData?.stake?.length > 0
                        ? profileData?.stake?.sort(function (a, b) {
                            return a - b;
                          })
                        : numberArray
                      )
                        ?.slice(0, 4)
                        ?.map((item) => {
                          return (
                            <Button
                              onClick={() => {
                                handelNumberChange(item);
                              }}
                              className="bg-yellow btn"
                            >
                              + {item}
                            </Button>
                          );
                        })}
                    </div>
                    <div>
                      <Button
                        className="bg-yellow"
                        onClick={() => handleStackSetting()}
                      >
                        <AiFillSetting className="fs-4" />
                      </Button>
                    </div>
                  </div>
                  <div className="betting-numbers d-flex align-items-center mb-2">
                    <div className="d-flex flex-wrap bet-numeric">
                      {numberArrayCount?.map((res) => {
                        if (res == "img") {
                          return (
                            <Button
                              onClick={() => {
                                clear();
                              }}
                              className="btn"
                            >
                              <img
                                src="../../assets/images/casino-icon/cross.png"
                                alt=""
                              />
                            </Button>
                          );
                        } else {
                          return (
                            <Button
                              onClick={() => handelDialPad(res)}
                              className="btn"
                            >
                              {res}
                            </Button>
                          );
                        }
                      })}
                    </div>
                    <div className="cross-bet">
                      <Button
                        onClick={() => {
                          setShowDial(false);
                        }}
                        className="btn h-100"
                        style={{ fontWeight: 600 }}
                      >
                        OK
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div
              style={{
                borderBottom: "1px solid #D4E0E5",
              }}
              class="item3 px-2 py-2 flex justify-between items-center"
            >
              <div class="py-1">
                <div class="mb-0.5 k">
                  <span style={{ fontSize: "1.2857142857142858rem" }}>
                    Total Stakes{" "}
                    <span style={{ fontWeight: "700" }}>{stake}</span>
                  </span>
                </div>
                <div class="text-base">
                  <span style={{ marginRight: "4px" }}>Max Profit</span>
                  <span style={{ fontWeight: "700" }}>
                    {totalOdds > 0 && stake > 0
                      ? ((totalOdds - 1) * stake)?.toFixed(2)
                      : 0}
                  </span>
                </div>
              </div>
              <button
                onClick={() => placeBet()}
                style={{
                  background: "#ffc800",
                  border: "none",
                  borderRadius: "20px",
                  fontWeight: "700",
                  width: "12.928571428571429rem",
                }}
                disabled={isDisabled()}
                class="btn-normal newPlaceButton p-3 font-bold rounded flex justify-center items-center disabled:bg-black-300 disabled:text-black-600 disabled:cursor-not-allowed w-181px h-50px d-flex justify-content-center items-center rounded-md bg-button-normal"
              >
                Place Bet
              </button>
            </div>
            <div class="p-2 d-flex justify-content-between align-item-center">
              <div class="d-flex justify-content-center align-item-center text-11 text-black-900">
                <input
                  style={{ marginRight: "5px" }}
                  id="accept-any-odds"
                  type="checkbox"
                />
                <span data-v-a5b7b01a="" class="flex items-center">
                  Accept any odds
                </span>
              </div>
              <div data-v-4ac8cb63="" class="flex items-center space-x-1">
                <mark
                  data-v-4ac8cb63=""
                  class="text-11 bg-black-300 text-black leading-normal px-2 rounded"
                >
                  Max
                </mark>
                <span
                  data-v-4ac8cb63=""
                  class="text-11 text-black-600 font-bold"
                ></span>
              </div>
            </div>
          </div>
        </div>
        {betLoader && (
          <div className="loading-bet">
            <img src={loading} />
          </div>
        )}
        {showStackSetting && (
          <StackSetting handleStackSetting={handleStackSetting} />
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default BetSlipParly;
