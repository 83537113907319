import React, { useEffect, useRef, useState } from "react";
import "./theme.css"; // Ensure this path is correct based on your project structure

const LiveMatchTrackerSoccer = ({ scoreId, type, tabPosition }) => {
  const widgetRef = useRef(null); // Create a ref to the widget container
  const [showLoader, setLoader] = useState(true);

  useEffect(() => {
    const loadScript = () => {
      return new Promise((resolve, reject) => {
        const existingScript = document.querySelector(
          'script[src="https://widgets.sir.sportradar.com/6d064d6729b83730f5320255e82e2411/widgetloader"]'
        );
        if (!existingScript) {
          const script = document.createElement("script");
          script.src =
            "https://widgets.sir.sportradar.com/6d064d6729b83730f5320255e82e2411/widgetloader";
          script.async = true;
          script.onload = () => resolve();
          script.onerror = () =>
            reject(new Error("Failed to load the SIR widget script."));
          document.body.appendChild(script);
        } else {
          resolve();
        }
      });
    };

    loadScript().then(() => {
      setTimeout(() => {
        if (window.SIR && widgetRef.current) {
          window.SIR("addWidget", widgetRef.current, "match.lmtPlus", {
            pitchCustomBgColor: "#127635",
            disablePitchNoise: true,
            disablePitchStripes: true,
            disablePitchOverlay: true,
            disablePitchSpotlights: true,
            pitchLightStyle: false,
            layout: "single",
            momentum: "extended",
            collapseTo: "momentum",
            tabsPosition: "top",
            matchId: scoreId,
          });
        } else {
          console.error("SIR is not defined or widget container is missing.");
        }
      }, 500); // Delay to ensure the DOM is fully loaded
    });

    return () => {
      const script = document.querySelector(
        'script[src="https://widgets.sir.sportradar.com/6d064d6729b83730f5320255e82e2411/widgetloader"]'
      );
      if (script) {
        document.body.removeChild(script);
      }
    };
  }, [scoreId]);

  useEffect(() => {
    setTimeout(() => {
      setLoader(false);
    }, 1500);
    // setLoader(false);
  }, []);

  return (
    <div
      className={`widgets ${type == "inner" ? "" : showLoader ? "" : "p-2"}`}
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        position: ``,
      }}
    >
      {type == "inner" ? (
        ""
      ) : (
        <style>
          {`.sr-bb {
  border-radius: 8px;
}`}{" "}
        </style>
      )}

      <div className="w-100" style={{ position: `relative` }}>
        {!showLoader
          ? ""
          : type !== "inner" && (
              <div
                id="loading1"
                className="loading1"
                style={{ display: `block` }}
              >
                <div className="loading-box1">
                  <div className="loading-spinner"></div>
                </div>
              </div>
            )}
        <div
          className="sr-widget sr-widget-1"
          style={{
            borderRadius: type == "inner" ? "" : "8px",
          }}
          ref={widgetRef} // Attach the ref to the container
        >
          {/* Widget content will be injected here by the external script */}
        </div>
      </div>
    </div>
  );
};

export default LiveMatchTrackerSoccer;
