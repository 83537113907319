import React, { useContext, useEffect, useState } from "react";
import BackLayHome from "./BackLayHome";
import BackLayFav from "./BackLayFav";
import AuthContext from "../context/AuthContext";
import { isEmpty, isNumber } from "lodash";
import { FaArrowsAltV } from "react-icons/fa";
import { priceFormat } from "../Utils/constants";
import MarketDepth from "./MarketDepth";
import { Button } from "react-bootstrap";
import BetSlipContext from "../context/BetSlipContext";

export const FavouriteDisplayMatch = ({
  runner,
  matchOdds,
  object,
  betSlipObject,
  index,
  handelChange,
  setSelect,
  scrollId,
  myRef,
  scrollHeight,
  res,
  position,
  type,
  totalMatched,
}) => {
  const { profileData } = useContext(AuthContext);
  const {showBetDialog} = useContext(BetSlipContext)
  const [localLimit, setLocalLimit] = useState({});
  const [showMarketDepthPopup, setShowMarketDepthPopup] = useState(false);
  const [marketDepthData, setMarketDepthData] = useState({});
  useEffect(() => {
    if (!isEmpty(profileData)) {
      let obj = profileData?.matchSetting?.find((temp) => {
        return (
          temp?.type == (type == "bookmaker" ? "bookmaker" : "betFaire") &&
          temp?.sportType == res?.gameType
        );
      });
      setLocalLimit(!isEmpty(obj) ? obj : {});
    }
  }, [profileData]);

  return (
    <div className="match-odd-table bg-white p-3 px-0 py-1">
      <div className="d-flex back-lay-head justify-content-end ms-auto pb-1">
        <span className="text-center w-100">Back</span>
        <span className="text-center w-100">Lay</span>
      </div>
      <ul className="mb-2 rounded position-relative p-0">
        {runner?.map((newObj) => {
          const odds_back = matchOdds?.rt
            ?.filter((rt) => {
              return rt.ib;
            })
            ?.filter((todd) => {
              return type !== "bookmaker"
                ? todd.ri == newObj?.SelectionId || newObj?.selectionID
                : todd?.runnerName == newObj?.runnerName;
            });
          const odds_lay = matchOdds?.rt
            ?.filter((rt) => {
              return !rt.ib;
            })
            ?.filter((todd) => {
              return type !== "bookmaker"
                ? todd.ri == newObj?.SelectionId || newObj?.selectionID
                : todd?.runnerName == newObj?.runnerName;
            });

          const odds_lay2 =
            matchOdds?.rt
              ?.filter((rt) => {
                return !rt.ib;
              })
              ?.find((todd) => {
                return type !== "bookmaker"
                  ? todd.ri == newObj?.SelectionId || newObj?.selectionID
                  : todd?.runnerName == newObj?.runnerName;
              }) || {};
          let odds_back2 =
            matchOdds?.rt
              ?.filter((rt) => {
                return rt.ib;
              })
              ?.find((todd) => {
                return type !== "bookmaker"
                  ? todd.ri == newObj?.SelectionId || newObj?.selectionID
                  : todd?.runnerName == newObj?.runnerName;
              }) || {};
          let layRt = odds_lay2?.rt > 0 ? odds_lay2?.rt : 0;
          let backRt = odds_back2?.rt > 0 ? odds_back2?.rt : 0;

          let disable = false;
          if (odds_lay?.length == 0 && odds_back?.length == 0) {
            disable = true;
          } else if (layRt <= 0 && backRt <= 0) {
            disable = true;
          } else if (
            (layRt > localLimit?.oddsLimit || layRt == 0) &&
            (backRt > localLimit?.oddsLimit || backRt == 0)
          ) {
            disable = true;
          } else {
            disable = false;
          }
          return (
            <li
              ref={index == scrollId ? myRef : null}
              style={{ scrollMarginTop: scrollHeight }}
            >
              <h2>
                {newObj?.RunnerName || newObj?.runnerName}{" "}
                {(Math?.abs(newObj?.position) > 0 ||
                  Math.abs(newObj?.newPosition) > 0) && (
                  <span
                    className={`d-block py-1 ${
                      newObj?.position > 0
                        ? "text-green team-red-score"
                        : "team-red-score"
                    }`}
                  >
                    {position?.length > 0 && isNumber(newObj?.position)
                      ? newObj?.position > 0
                        ? Math?.abs(newObj?.position).toFixed(2)
                        : `( ${Math.abs(newObj?.position).toFixed(2)} )`
                      : ""}
                    {object?.eventId == betSlipObject?.object?.eventId &&
                      Math.abs(newObj?.newPosition) >= 0  && showBetDialog && (
                        <span
                          className={
                            newObj?.newPosition > 0
                              ? "text-green"
                              : "team-red-score"
                          }
                        >
                          {" "}
                          {">"}{" "}
                          {newObj?.newPosition > 0
                            ? Math.abs(newObj?.newPosition).toFixed(2)
                            : `( ${Math.abs(newObj?.newPosition).toFixed(2)} )`}
                        </span>
                      )}
                  </span>
                )}
              </h2>

              {odds_back?.length > 0 || odds_lay?.length > 0 ? (
                <BackLayFav
                  component={type}
                  type="sport"
                  index={index}
                  conditionNew={disable && matchOdds?.ms == 1}
                  item={newObj}
                  object={object}
                  res={res || {}}
                  setSelect={setSelect}
                  handelChange={handelChange}
                  odds_lay={odds_lay}
                  odds_back={odds_back}
                  limit={localLimit}
                />
              ) : (
                <div
                  className={`lay-back-table ${
                    type == "bookmaker" ? "bg-bookmaker-market" : ""
                  }`}
                >
                  <div className="back">
                    <span>
                      <span style={{ fontWeight: "700" }}></span>
                      {type == "bookmaker" ? (
                        ""
                      ) : (
                        <span className="d-block"></span>
                      )}
                    </span>
                  </div>
                  <div className="lay">
                    <span>
                      <span style={{ fontWeight: "700" }}></span>

                      {type == "bookmaker" ? (
                        ""
                      ) : (
                        <span className="d-block"></span>
                      )}
                    </span>
                  </div>
                </div>
              )}
              {disable && matchOdds?.ms == 1 && matchOdds?.rt?.length !== 0 && (
                <div className="overlay-match">Suspended</div>
              )}
            </li>
          );
        })}
        {(matchOdds?.ms != 1 || matchOdds?.rt?.length == 0) && (
          <div className="overlay-match">Suspended</div>
        )}
      </ul>
      <div
        className="market-depth-sec d-flex"
        style={{
          justifyContent:
            type == "betfair" && res?.hasMatchOdds ? `space-between` : "end",
          textAlign: `left`,
        }}
      >
        {type == "betfair" && res?.hasMatchOdds && (
          <Button
            className="text-white border-dark d-flex align-items-center"
            onClick={() => {
              setShowMarketDepthPopup(true);
              setMarketDepthData({
                runner: runner,
                backOdds: matchOdds?.rt?.filter((rt) => {
                  return rt.ib;
                }),
                layOdds: matchOdds?.rt?.filter((rt) => {
                  return !rt.ib;
                }),
                totalMatched: totalMatched,
              });
            }}
          >
            <img src="../../assets/images/casino-icon/graph-icon.png" alt="" />
            Market Depth
          </Button>
        )}
        <div className="d-flex align-items-center min-max">
          <FaArrowsAltV />
          <span>min/max</span>
          <strong>
            {localLimit?.minAmount || 1}/
            {priceFormat(localLimit?.maxAmount) || 100}
          </strong>
        </div>
      </div>
      {showMarketDepthPopup && (
        <MarketDepth
          data={marketDepthData}
          detail={res}
          setShowMarketDepthPopup={setShowMarketDepthPopup}
        />
      )}
    </div>
  );
};
