import React, { useContext, useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import OutsideClickHandler from "react-outside-click-handler";
import { FaAngleRight } from "react-icons/fa";
import AuthContext from "../context/AuthContext";
import { BiSolidRightArrow } from "react-icons/bi";
import { isEmpty } from "lodash";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import obj from "../Utils/constants";
const BookPosition = () => {
  const { setShowPosition, showPosition } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const getFancyBetPosition = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.bookPosition +
        `?eventId=${showPosition?.object?.eventId}&type=${
          obj.betCheckObj[showPosition?.object?.categoryType]
        }&selectionId=${showPosition?.object?.selectionId}&marketId=${
          showPosition?.object?.marketId
        }`
    );

    if (status === 200) {
      if (response_users.success) {
        setData(response_users.results);
      }
    }
  };
  useEffect(() => {
    if (showPosition?.status && !isEmpty(showPosition?.object)) {
      getFancyBetPosition();
    }
  }, [showPosition]);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowPosition({
          status: false,
          object: {},
        });
      }}
    >
      <div className="market-depth-modal">
        <div className="market-title">
          <h4 className="text-white">Book Position</h4>
          <Button
            onClick={() =>
              setShowPosition({
                status: false,
                object: {},
              })
            }
            className="border-0 abc text-white position-absolute end-0 top-0 pt-1 fs-4 bg-transparent border-0"
          >
            <RxCross2 />
          </Button>
        </div>

        <div className="market-title p-2">
          <p className="book-position-title m-0">
            <span className="first-span"> {showPosition?.object?.match}</span>{" "}
            <BiSolidRightArrow /> <span>{showPosition?.object?.fancyName}</span>
          </p>
        </div>
        <div className="runs-book-position">Runs</div>
        <div className="market-depth-body">
          <table id="table" className="table table-fancy">
            <tbody>
              {data?.bets?.length > 0 &&
                data?.bets?.map((res) => {
                  return (
                    <tr>
                      <td
                        style={{
                          backgroundColor:
                            res?.position > 0
                              ? "rgb(208 232 250)"
                              : "rgb(253 226 232)",
                        }}
                      >
                        {res.betRun}
                      </td>
                      <td
                        style={{
                          backgroundColor:
                            res?.position > 0
                              ? "rgb(208 232 250)"
                              : "rgb(253 226 232)",
                        }}
                      >
                        {res.position >= 0 ? (
                          res.position
                        ) : (
                          <span style={{ color: `red` }}>
                            ({Math.abs(res.position.toFixed(2))})
                          </span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              {data?.noBet?.length > 0 &&
                data?.noBet?.map((res) => {
                  return (
                    <>
                      <tr id="runsEx" style={{ display: "table-row" }}>
                        <td id="runs" className="col-back">
                          {res.profitRunRange}
                        </td>
                        <td id="exposure" className="col-back">
                          {res.positionProfitAmount.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td id="runs" className="col-lay">
                          {res.lossRunRange}
                        </td>
                        <td id="exposure" className="col-lay text-danger">
                          {`(- ${res.positionLoseAmount.toFixed(2)})`}
                        </td>
                      </tr>
                      <tr>
                        <td id="runs" className="col-lay ">
                          {res.lossRunRange + 1}
                        </td>
                        <td id="exposure" className="col-lay text-danger">
                          {`(- ${res.positionLoseAmount.toFixed(2)})`}
                        </td>
                      </tr>
                    </>
                  );
                })}
              {data?.yesBet?.length > 0 &&
                data?.yesBet?.map((res) => {
                  return (
                    <>
                      <tr id="runsEx" style={{ display: "table-row" }}>
                        <td id="runs" className="col-back">
                          {res.profitRunRange}
                        </td>
                        <td id="exposure" className="col-back">
                          {res.positionProfitAmount.toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <td id="runs" className="col-lay">
                          {res.lossRunRange}
                        </td>
                        <td id="exposure" className="col-lay text-danger">
                          {`(- ${res.positionLoseAmount.toFixed(2)})`}
                        </td>
                      </tr>
                      <tr>
                        <td id="runs" className="col-lay">
                          {res.lossRunRange - 1}
                        </td>
                        <td id="exposure" className="col-lay text-danger">
                          {`(- ${res.positionLoseAmount.toFixed(2)})`}
                        </td>
                      </tr>
                    </>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default BookPosition;
