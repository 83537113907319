import React, { useContext, useEffect, useState } from "react";
import LayoutComponent from "../components/shared/Layout";
import GameMenu from "../components/GameMenu";
import SportAccordian from "../components/SportAccordian";
import {
  Accordion,
  Button,
  Dropdown,
  DropdownButton,
  Form,
} from "react-bootstrap";
import { AiFillStar } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { BiSortAlt2 } from "react-icons/bi";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import AuthContext from "../context/AuthContext";
import NoEvent from "../components/NoEvent";
import { io } from "socket.io-client";
import {
  MdKeyboardDoubleArrowDown,
  MdKeyboardDoubleArrowUp,
} from "react-icons/md";
import SearchBar from "../components/SearchBar";
import BetSlipContext from "../context/BetSlipContext";
import { isEmpty, startCase } from "lodash";
import { createPortal } from "react-dom";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useLocation, useNavigate } from "react-router-dom";
import { FaAngleRight, FaArrowsAltV } from "react-icons/fa";
import BackLay from "../components/BackLay";
import SportAccordianInner from "../components/SportAccordianInner";
import SportAccordianInnerFav from "../components/SportAccordianInnerFav";
const Favourite = () => {
  const {
    setGameTab,
    gameTab,
    keyTime,
    setKeyTime,
    user,
    profileData,
    setSearchTab,
    searchTab,
    parly,
    setParly,
  } = useContext(AuthContext);
  const { betPlace } = useContext(BetSlipContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [loader, setLoader] = useState(false);
  const [odds, setOdds] = useState({});
  const [list, setList] = useState([]);
  const [activeFav, setActiveFav] = useState([]);
  const [position, setPosition] = useState([]);
  const addToFavioute = async (
    id,
    favoriteType,
    favoriteMarketId,
    detail,
    market
  ) => {
    const { status, data: response_users } = await apiGet(
      apiPath.addToFavourite +
        `?eventId=${id}&favoriteType=${favoriteType}&favoriteMarketId=${favoriteMarketId}&eventType=${
          detail?.eventType
        }&market=${market ? market : ""}`
    );
    if (response_users?.success) {
      getData();
    }
  };
  const [ids, setIds] = useState({
    eventId: [],
    marketId: [],
  });
  const getData = async () => {
    setLoader(true);
    const { status, data } = await apiGet(apiPath.favourtieList);
    if (data?.success) {
      setList(
        data?.results?.sort(
          (a, b) => new Date(a.eventDateTime) - new Date(b.eventDateTime)
        )
      );
      let resultMatchOdds = data?.results?.map((res) => {
        return res?.eventId;
      });
      let resultMarket = data?.results?.map((res) => {
        return res?.marketId;
      });
      setIds({
        eventId: resultMatchOdds,
        marketId: resultMarket,
      });

      if (resultMatchOdds?.length > 0) {
        getBetPosition(resultMarket, resultMatchOdds);
        getMatchOddsMulti(resultMatchOdds, resultMarket);
      }
      setLoader(false);
    } else {
      setLoader(false);
    }
  };

  const getMatchOddsMulti = async (id, idMarket) => {
    const { status, data: response_users } = await apiGet(
      apiPath.getMatchOddsNew +
        `?eventId=${id?.join(",")}&marketId=${idMarket?.join(",")}`
    );
    if (status === 200) {
      if (response_users.success) {
        const socket_data = response_users?.results;
        setOdds(socket_data);
      }
    }
  };

  useEffect(() => {
    if (betPlace?.isPlaced && betPlace?.type == "matched") {
      getBetPosition(ids?.marketId, ids?.eventId);
    }
  }, [betPlace, ids]);
  useEffect(() => {
    getData();
  }, [gameTab, keyTime]);

  useEffect(() => {
    if (ids?.eventId?.length > 0) {
      let time = setInterval(() => {
        getMatchOddsMulti(ids?.eventId, ids?.marketId);
      }, 2000);
      return () => {
        clearInterval(time);
      };
    }
  }, [ids]);

  const getBetPosition = async (item, eventId) => {
    const { status, data: response_users } = await apiGet(
      apiPath.betPosition +
        `?eventIds=${eventId}&multi=true&marketId=${item?.join(
          ","
        )}&isFavorite=true`
    );
    if (status === 200) {
      setPosition(response_users?.results);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  return (
    <div>
      <LayoutComponent visibilityType={true}>
        {searchTab ? (
          <SearchBar />
        ) : (
          <>
            <div className="parlay-tab">
              <Form className="d-flex align-items-center">
                <div
                  style={{ alignItems: "center" }}
                  className="parlay-checkbox d-flex"
                >
                  <Form.Check
                    onClick={() => {
                      if (location?.pathname == "/favourite") {
                        navigate("/sports");
                        setParly(!parly);
                      }
                    }}
                    checked={parly}
                    className="parly-check"
                    type="switch"
                  />
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                      color: parly ? "#ffc800" : "",
                    }}
                  >
                    Parlay
                  </span>
                </div>
                <div className="parlay-inner-tab w-100 justify-content-between d-flex align-items-center">
                  <div>
                    <ul className="mb-0 p-0">
                      <li
                        onClick={() => {
                          setKeyTime("in_play");
                          navigate("/sports");
                        }}
                      >
                        In-Play
                      </li>
                      <li
                        onClick={() => {
                          setKeyTime("today");
                          navigate("/sports");
                        }}
                      >
                        Today
                      </li>
                      <li
                        onClick={() => {
                          setKeyTime("tomorrow");
                          navigate("/sports");
                        }}
                      >
                        Tomorrow
                      </li>
                    </ul>
                  </div>

                  <div className="parlay-icons">
                    <i
                      class="icon-star-solid text-icon-light "
                      style={{ color: "#ffc800" }}
                    ></i>

                    <i
                      class="icon-search text-icon-light "
                      onClick={() => setSearchTab(true)}
                    ></i>
                  </div>
                </div>
              </Form>
            </div>

            {loader ? (
              <div>
                <SkeletonTheme baseColor="#d4e0e5">
                  <div className="p-2">
                    <div className="skeletion-new p-3">
                      <div className="d-flex mb-2">
                        <Skeleton
                          circle
                          height={20}
                          width={20}
                          style={{ marginRight: "10px" }}
                        />
                        <Skeleton height={17} width={120} />{" "}
                      </div>
                      <Skeleton height={17} width={"100%"} />{" "}
                      <Skeleton height={17} width={"70%"} />
                    </div>
                  </div>
                </SkeletonTheme>
                <SkeletonTheme baseColor="#d4e0e5">
                  <div className="p-2">
                    <div className="skeletion-new p-3">
                      <div className="d-flex mb-2">
                        <Skeleton
                          circle
                          height={20}
                          width={20}
                          style={{ marginRight: "10px" }}
                        />
                        <Skeleton height={17} width={120} />{" "}
                      </div>
                      <Skeleton height={17} width={"100%"} />{" "}
                      <Skeleton height={17} width={"70%"} />
                    </div>
                  </div>
                </SkeletonTheme>{" "}
                <SkeletonTheme baseColor="#d4e0e5">
                  <div className="p-2">
                    <div className="skeletion-new p-3">
                      <div className="d-flex mb-2">
                        <Skeleton
                          circle
                          height={20}
                          width={20}
                          style={{ marginRight: "10px" }}
                        />
                        <Skeleton height={17} width={120} />{" "}
                      </div>
                      <Skeleton height={17} width={"100%"} />{" "}
                      <Skeleton height={17} width={"70%"} />
                    </div>
                  </div>
                </SkeletonTheme>{" "}
              </div>
            ) : list?.length > 0 ? (
              <div className="bg-skylight p-2">
                {list?.length > 0 ? (
                  list?.map((object, index) => {
                    return (
                      <SportAccordianInnerFav
                        index={index}
                        keyTime={keyTime}
                        matchOdds={
                          odds[object?.eventId]?.find((res) => {
                            return res?.type == "odds";
                          })?.data || {}
                        }
                        matchBookmaker={
                          odds[object?.eventId]?.find((res) => {
                            return res?.type == "bookmaker";
                          })?.data || {}
                        }
                        matchFancy={
                          odds[object?.eventId]
                            ?.find((res) => {
                              return res?.type == "fancy";
                            })
                            ?.data?.filter((item) => {
                              return item?.eventId == object?.eventId;
                            }) || {}
                        }
                        matchSportBook={
                          odds[object?.eventId]
                            ?.find((res) => {
                              return res?.type == "sportbook";
                            })
                            ?.data?.filter((item) => {
                              return item?.betfairEventId == object?.eventId;
                            }) || {}
                        }
                        object={object}
                        position={
                          position?.length > 0
                            ? position?.find((res) => {
                                return res?.eventId == object?.eventId;
                              }) || {}
                            : {}
                        }
                        activeFav={activeFav}
                        setActiveFav={setActiveFav}
                        ids={ids}
                        addToFavioute={addToFavioute}
                      />
                    );
                  })
                ) : (
                  <NoEvent />
                )}
              </div>
            ) : (
              <NoEvent />
            )}
          </>
        )}
      </LayoutComponent>
    </div>
  );
};

export default Favourite;
