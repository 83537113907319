const axios = require("axios");

function getHeaders() {
  return { "Content-Type": "application/json" };
}
const instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  // timeout: 1000,
  headers: getHeaders(),
});

instance.interceptors.request.use((config) => {
  if (
    ![
      "/v1/user/refresh-token",
      "/v1/user/refresh-amount",
      "/v1/user/match/betfaire-place-bet",
      "/v1/user/match/bookmaker-place-bet",
      "/v1/user/match/get-bet-positions",
      "/v1/user/match/premium-fancy-place-bet",
      "/v1/user/match/fancy-bet-positions",
      "/v1/user/match/sport-book-bet-positions",
      "/v1/user/match/check-bet-price",
      "/v1/user/match/place-toss-bet",
      "/v1/user/match/place-tie-bet",
      "/v1/user/match/fancy-place-bet",
      "/v1/user/match/mobile-list",
      "/v1/cron/match-odds",
      "/v1/cron/premium-fancy",
      "/v1/cron/match-odds",
      "/v1/user/message/list",
      "/v1/user/profile",
      "/v1/user/sport/mobile-inplay-count",
      "/v1/user/match/current-bet-count",
      "/v1/user/get-home-banners",
      "/v1/user/match/series",
    ].includes(config?.url?.split("?")[0])
  ) {
    // document.getElementById("loading").style.display = "flex";
  }
  // if (["/v1/user/match/series"].includes(config?.url?.split("?")[0])) {
    // document.getElementById("loading").style.display = "flex";
  // }
  const token = localStorage.getItem("token");
  if (!token) {
    return config;
  }
  config = {
    ...config,
    headers: { ...config.headers, Authorization: `Bearer ${token}` },
  };
  return config;
});

instance.interceptors.response.use(
  function (response) {
    // Do something with response data
    // document.getElementById("loading").style.display = "none";
    return response;
  },
  function (error) {
    const { status } = error.response;
    // document.getElementById("loading").style.display = "none";
    if (status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("uniqueId");
      window.location.reload();
    } else if (status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("uniqueId");
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

function apiGet(url, params = {}) {
  return instance.get(url, { params });
}

function apiPost(url, body) {
  return instance.post(url, body);
}

function apiPut(url, body) {
  return instance.put(url, body);
}

function apiDelete(url) {
  return instance.delete(url);
}

export { getHeaders, apiGet, apiPost, apiPut, apiDelete };
