import React, { useEffect, useState } from "react";
import GameCard from "../GameCard";
import GameSidebar from "../GameSidebar";
import { gamesLiveArray } from "../../constraints/constants";
import { Button } from "react-bootstrap";
import { IoIosArrowForward } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const AwcGame = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [homePlayType, setHomePlayType] = useState("latest");
  useEffect(() => {
    setHomePlayType("latest");
    setData(
      gamesLiveArray?.filter((res) => {
        return res?.type == "AWC";
      })
    );
  }, []);
  const handelChange = (value) => {
    setHomePlayType(value);
    if (value == "latest") {
      setData(
        gamesLiveArray
          ?.filter((res) => {
            return res?.type == "AWC";
          })
          ?.slice(0, 12)
      );
    } else {
      setData((prev) => {
        return prev.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        });
      });
    }
  };
  const bgHeader = [
    { type: "BG", label: "BG" },
    { type: "BTG", label: "BTG" },
    { type: "E1SPORT", label: "E1Sport" },
    { type: "NLC", label: "NLC" },
    { type: "RT", label: "RT" },
    { type: "SV388", label: "SV388" },
    { type: "YesBingo", label: "YesBingo" },
    { type: "YL", label: "YL" },
  ];
  const [activeHeader, setActiveHeader] = useState("BG");
  return (
    <div className="games-inner">
      <GameSidebar
        sideDataType="tableData"
        homePlayType={homePlayType}
        setHomePlayType={handelChange}
      />
      <div className="games-wrapper">
        {/* <div class=" d-flex flex-wrap" style={{ marginBottom: "10px" }}>
          {bgHeader?.map((res) => {
            return (
              <div
                onClick={() => {
                  setActiveHeader(res?.type);
                }}
                id="casino-KINGMAKER"
                class={`z-10 awc-outer ${
                  res?.type == activeHeader ? "activetabs" : ""
                } `}
              >
                <span class="px-2 mx-2 h-24px text-13 flex items-center font-bold rounded text-black text-opacity-50 cursor-pointer ng-tns-c43-1">
                  <span class="text-global-font-on-dark soan_color ng-tns-c43-1">
                    {res?.label}
                  </span>
                </span>
              </div>
            );
          })}
        </div> */}
        <div className="games-card-inner">
          <div className="row">
            {data
              ?.filter((res) => {
                return res?.cateogry == activeHeader;
              })
              ?.map((res, index) => {
                return <GameCard key={index} res={res} />;
              })}
          </div>
        </div>
        <div className="w-100 d-flex justify-content-center align-items-center">
          <Button
            onClick={() => {
              navigate(`/casino?category=AWC&type=${activeHeader}`);
            }}
            style={{ background: "black", border: "none" }}
          >
            Show More <IoIosArrowForward />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AwcGame;
