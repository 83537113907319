import { isNaN } from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { TiArrowSortedUp } from "react-icons/ti";
import { Col } from "react-bootstrap";
import AuthContext from "../context/AuthContext";
const SportBookInner = ({
  oldObject,
  res,
  handelChange,
  item,
  index,
  parly,
  activeClass,
  newPosition,
  conditionPosition,
  setSelected,
  selectSpark,conditionShowPositon
}) => {
  const [green, setGreen] = useState(false);
  const [red, setRed] = useState(false);
  const redRef = useRef(false);
  const greenRef = useRef(false);
  const { setMessage, message } = useContext(AuthContext);
  useEffect(() => {
    if (res?.odds > oldObject?.odds) {
      setGreen(true);
      setRed(false);
    } else if (res?.odds < oldObject?.odds) {
      setGreen(false);
      setRed(true);
    } else if (res?.odds == oldObject?.odds) {
      if (red && !redRef.current) {
        redRef.current = true; // Mark as executed
        let timeout = setTimeout(() => {
          setRed(false);
          redRef.current = false;
        }, 1000);
        return () => {
          clearTimeout(timeout);
        };
      }
      if (green && !greenRef.current) {
        greenRef.current = true;
        let timeout = setTimeout(() => {
          setGreen(false);
          greenRef.current = false;
        }, 1000);
        return () => {
          clearTimeout(timeout);
        };
      }
    }
  }, [oldObject, res, green, red, redRef, greenRef]);


  return (
    <Col className="position-relative" xs={6}>
      <div
        onClick={() => {
          // if (res?.odds >= 1.7 || !parly) {
            handelChange(res?.odds, item, res, index);
            setSelected(item?.id);
          // } else {
          //   setMessage({
          //     ...message,
          //     status: true,
          //     message: "Min Odds Alowed 1.7",
          //     errorType: "Error",
          //   });
          // }
        }}
        title={res?.odds}
        id={"sportBook" + res?.id}
        className={`text-center position-relative team-wise-score ${
          res?.odds > oldObject?.odds || res?.odds < oldObject?.odds ? "spark-parly" : ""
        } ${selectSpark?.includes(index) ? "spark-parly" : ""} ${
          parly ? "parly-wise-score" : ""
        } ${
          (!parly && Math.abs(res?.position) >= 0) || conditionPosition
            ? "postion-with-height"
            : ""
        } ${activeClass}  `}
      >
        {green && (
          <TiArrowSortedUp
            style={{
              position: "absolute",
              top: "-5%",
              right: "-1%",
              transform: "rotate(45deg)",
            }}
            size={19}
            color="green"
          />
        )}
        {red && (
          <TiArrowSortedUp
            style={{
              position: "absolute",
              bottom: "-5%",
              right: "-1%",
              transform: "rotate(135deg)",
            }}
            size={19}
            color="red"
          />
        )}
        <span style={parly ? { fontWeight: "400" } : {}} className="d-block">
          {res?.selectionName}
        </span>
        <strong
          style={
            green
              ? {
                  color: "green",
                }
              : red
              ? { color: "red" }
              : {}
          }
        >
          {res?.odds}
        </strong>
        <div className="d-flex">
          {!parly && conditionShowPositon && (
            <span
              className={`${res?.position > 0 ? "text-green " : "text-red "}`}
              style={{
                background: "#c7e4f8",
                borderRadius: "8px",
                textAlign: "center",
                padding: "1px 5px",
              }}
            >
              {res?.position > 0
                ? Math.abs(res?.position).toFixed(2)
                : `( ${Math.abs(res?.position).toFixed(2)} )`}
            </span>
          )}
          {!parly && !isNaN(Math.abs(newPosition) > 0) && conditionPosition && (
            <span
              className={`${newPosition > 0 ? "text-green " : "text-red "}`}
              style={{
                background: "#c7e4f8",
                borderRadius: "8px",
                textAlign: "center",
                padding: "1px 5px",
                marginLeft: "5px",
              }}
            >
              {/* <MdKeyboardArrowRight /> */}

              {newPosition > 0
                ? Math.abs(newPosition).toFixed(2)
                : `( ${Math.abs(newPosition).toFixed(2)} )`}
            </span>
          )}
        </div>
      </div>
    </Col>
  );
};

export default SportBookInner;
