import React, { useContext } from "react";
import BetSlipContext from "../context/BetSlipContext";
import { isEmpty } from "lodash";
import { useNavigate } from "react-router-dom";
const BackLay = ({
  component,
  handelChange,
  odds_lay,
  odds_back,
  index,
  item,
  limit,
  marketName,
  disabelBack,
  disabelLay,
  conditionNew,
  oldBack,
  oldLay,
}) => {
  const { betSlipObject, showBetDialog } = useContext(BetSlipContext);
  let backClass = `back ${
    Number(
      document
        .getElementById(
          "back_odds_" + (item.SelectionId || item?.selectionID) + index
        )
        ?.title?.split("-")[0]
        ?.trim()
    ) !== odds_back[0]?.rt
      ? "spark-back"
      : ""
  } ${
    Number(
      document
        .getElementById(
          "back_odds_" + (item.SelectionId || item?.selectionID) + index
        )
        ?.title?.split("-")[1]
        ?.trim()
    ) !== odds_back[0]?.bv
      ? "spark-back"
      : ""
  } ${
    betSlipObject?.teamName == item?.RunnerName &&
    betSlipObject?.odds == odds_back[0]?.rt &&
    showBetDialog
      ? "back-active"
      : ""
  }`;
  let layClass = `lay ${
    Number(
      document
        .getElementById(
          "lay_odds_" + (item.SelectionId || item?.selectionID) + index
        )
        ?.title?.split("-")[0]
        ?.trim()
    ) !== odds_lay[0]?.rt ||
    Number(
      document
        .getElementById(
          "lay_odds_" + (item.SelectionId || item?.selectionID) + index
        )
        ?.title?.split("-")[1]
        ?.trim()
    ) !== odds_lay[0]?.bv
      ? "spark-lay"
      : ""
  } ${
    betSlipObject?.teamName == item?.RunnerName &&
    betSlipObject?.odds == odds_lay[0]?.rt &&
    showBetDialog
      ? "lay-active"
      : ""
  }`;

  let check = (odds) => {
    if (!conditionNew) {
      if (Number(odds) > 0) {
        if (Number(odds) > limit?.oddsLimit) {
          return "single-disabled";
        } else {
          return "";
        }
      } else {
        return "single-disabled";
      }
    }
  };

  let checkDisabled = (odds) => {
    if (Number(odds) > 0) {
      if (Number(odds) > limit?.oddsLimit) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  let backCheck = check(odds_back[0]?.rt, component, disabelBack) || "";
  let layCheck = check(odds_lay[0]?.rt, component, disabelLay) || "";
  return (
    <div
      className={`lay-back-table ${
        component == "bookmaker" ? "bg-bookmaker-market" : ""
      }`}
    >
      <div
        title={`${oldBack?.length > 0 && oldBack[0]?.rt}-${
          oldBack?.length > 0 && oldBack[0]?.bv
        }`}
        id={"back_odds_" + (item.SelectionId || item?.selectionID) + index}
        onClick={() => {
          if (component == "betfair" && disabelBack) {
          } else {
            if (!checkDisabled(odds_back[0]?.rt)) {
              handelChange(odds_back[0], "back", item?.RunnerName, item, index);
            }
          }
        }}
        className={backClass}
      >
        <span className={backCheck}>
          {backCheck == "single-disabled" && <span className="disabled"></span>}
          <span style={{ fontWeight: "700" }}>{odds_back[0]?.rt || ""}</span>
          {component == "bookmaker" ? (
            ""
          ) : (
            <span className="d-block">{odds_back[0]?.bv || ""}</span>
          )}
        </span>
      </div>
      <div
        title={`${oldLay?.length > 0 && oldLay[0]?.rt}-${
          oldLay?.length > 0 && oldLay[0]?.bv
        }`}
        id={"lay_odds_" + (item.SelectionId || item?.selectionID) + index}
        onClick={() => {
          if (component == "betfair" && marketName == "To Win the Toss") {
          } else {
            if (component == "betfair" && disabelLay) {
            } else {
              if (!checkDisabled(odds_lay[0]?.rt)) {
                handelChange(odds_lay[0], "lay", item?.RunnerName, item, index);
              }
            }
          }
        }}
        className={layClass}
      >
        <span className={layCheck}>
          {layCheck == "single-disabled" && <span className="disabled"></span>}
          <span style={{ fontWeight: "700" }}>{odds_lay[0]?.rt || ""}</span>
          {component == "bookmaker" ? (
            ""
          ) : (
            <span className="d-block">{odds_lay[0]?.bv || ""}</span>
          )}
        </span>
      </div>
    </div>
  );
};

export default BackLay;
