import React, { useContext, useEffect, useRef, useState } from "react";
import { Accordion, Button, Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { isEmpty } from "lodash";
import BetSlipContext from "../context/BetSlipContext";
import useScroll from "./Scroll";
import { useNavigate } from "react-router-dom";
import { AiOutlineStar } from "react-icons/ai";
import AuthContext from "../context/AuthContext";
import secureLocalStorage from "react-secure-storage";
import { FaRegArrowAltCircleUp } from "react-icons/fa";
import SportBookInner from "./SportBookInner";
import SportBookMatchInner from "./SportBookMatchInner";
const SportsBook = ({
  handleShowInfo,
  data,
  user,
  detail,
  profileData,
  setBetSlipObject,
  setShowBetDialog,
  setLimit,
  scrollHeight,
  addToFavioute,
  favouriteList,
  position,
}) => {
  const { parly, parlyBet, setParlyBet } = useContext(AuthContext);
  const naviagte = useNavigate();
  const [selected, setSelected] = useState("");
  const [selectSpark, setSelectSpark] = useState([0, 1, 2, 3, 4]);
  const [selectSport, setSelectSport] = useState("all");
  const { betSlipObject, showBetDialog } = useContext(BetSlipContext);
  const myRef = useRef(null);
  const [scrollId, setScrollId] = useScroll(myRef);
  var settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 4.2,
    slidesToScroll: 1,
  };
  const [localLimit, setLocalLimit] = useState({});
  const handelChange = (odds, item, res, index) => {
    if (!isEmpty(user)) {
      if (odds > 0) {
        if (!parly) {
          setScrollId(index);
          setShowBetDialog(true);
          setLimit(localLimit);
          setBetSlipObject((prev) => ({
            ...prev,
            odds: odds,
            eventId: detail?.eventId,
            marketId: detail?.marketId,
            teamName: res?.selectionName,
            item: { ...item },
            playType: "sportBook",
            object: {
              ...res,
            },
          }));
        } else {
          setParlyBet((current) => {
            let newObj = {
              ...item,
              sportsBookSelectionObject: res,
              selectObj: res?.id,
              newEventId: detail?.eventId,
              eventName: detail?.eventName,
              marketId: detail?.marketId,
            };
            if (current?.length > 0) {
              if (
                current?.filter((temp) => {
                  return temp?.newEventId == detail?.eventId;
                })?.length > 0
              ) {
                if (
                  current?.filter((temp) => {
                    return temp?.newEventId !== detail?.eventId;
                  })?.length > 0
                ) {
                  secureLocalStorage.setItem("parlyBet", [
                    ...current?.filter((temp) => {
                      return temp?.newEventId !== detail?.eventId;
                    }),
                    newObj,
                  ]);
                  return [
                    ...current?.filter((temp) => {
                      return temp?.newEventId !== detail?.eventId;
                    }),
                    newObj,
                  ];
                } else {
                  secureLocalStorage.setItem("parlyBet", [newObj]);
                  return [newObj];
                }
              } else {
                secureLocalStorage.setItem("parlyBet", [...current, newObj]);
                return [...current, newObj];
              }
            } else {
              secureLocalStorage.setItem("parlyBet", [newObj]);
              return [newObj];
            }
          });
        }
      }
    } else {
      naviagte("/login");
    }
  };
  useEffect(() => {
    let obj = profileData?.matchSetting?.find(
      (res) =>
        res?.type == "sportBook" &&
        (profileData?.arrayCheck == "details"
          ? true
          : res?.sportType == detail?.gameType)
    );
    if (!isEmpty(obj)) {
      setLocalLimit(obj);
    } else {
      setLocalLimit({
        maxAmount: 1000,
        maxProfit: 10000,
        minAmount: 1,
        oddsLimit: 10,
      });
    }
  }, []);

  const sportBookCheck = (parlyBet, detail, res, parly) => {
    if (isEmpty(user)) {
      return "";
    } else {
      let find = parlyBet?.find((res) => {
        return res?.newEventId == detail?.eventId;
      });
      if (!isEmpty(find)) {
        return find?.sportsBookSelectionObject?.id == res?.id
          ? "active-sportBook-parly"
          : "";
      } else {
        return "";
      }
    }
  };

  const [updatedData, setUpdatedData] = useState([]);
  useEffect(() => {
    let filtered = data?.filter((res) => {
      return res?.marketName?.toLowerCase()?.includes("1x2");
    });
    let filteredNot = data?.filter((res) => {
      return !res?.marketName?.toLowerCase()?.includes("1x2");
    });
    let newUpdatedResult = [...filtered, ...filteredNot];
    if (favouriteList?.length > 0 && newUpdatedResult?.length > 0) {
      newUpdatedResult = reorderArray(favouriteList, newUpdatedResult);
    }
    if (selectSport == "Innings") {
      setUpdatedData(
        newUpdatedResult?.filter((res) => {
          return res?.marketName?.toLowerCase()?.includes("innings");
        })
      );
    } else if (selectSport == "Over") {
      setUpdatedData(
        newUpdatedResult?.filter((res) => {
          return (
            res?.marketName?.toLowerCase()?.includes("over") &&
            !res?.marketName?.toLowerCase()?.includes("winner")
          );
        })
      );
    } else if (selectSport == "Match") {
      setUpdatedData(
        newUpdatedResult?.filter((res) => {
          return (
            res?.marketName?.toLowerCase()?.includes("winner") ||
            res?.marketName?.toLowerCase()?.includes("innings")
          );
        })
      );
    } else if (selectSport == "Batsman") {
      setUpdatedData(
        newUpdatedResult?.filter((res) => {
          return (
            !res?.marketName?.toLowerCase()?.includes("over") &&
            res?.marketName?.toLowerCase()?.includes("innings") &&
            res?.marketName?.toLowerCase()?.includes("total")
          );
        })
      );
    } else {
      // if (favouriteList?.length > 0 && newUpdatedResult?.length > 0) {
      //   setUpdatedData(reorderArray(favouriteList, newUpdatedResult));
      // } else {
      setUpdatedData(newUpdatedResult);
      // }
    }
  }, [selectSport, data, favouriteList]);

  // useEffect(() => {
  //   let filtered = data?.filter((res) => {
  //     return res?.marketName?.toLowerCase()?.includes("1x2");
  //   });
  //   let filteredNot = data?.filter((res) => {
  //     return !res?.marketName?.toLowerCase()?.includes("1x2");
  //   });
  //   setUpdatedData([...filtered, ...filteredNot]);
  // }, [detail, data]);

  const reorderArray = (ids, data) => {
    const favoriteIdsSet = new Set(ids);
    const favorites = data.filter((item) => {
      return favoriteIdsSet.has(Number(item.id));
    });
    const nonFavorites = data.filter((item) => {
      return !favoriteIdsSet.has(Number(item.id));
    });

    const reorderedArray = [...favorites, ...nonFavorites];
    return reorderedArray;
  };

  useEffect(() => {
    let timer = setTimeout(() => {
      setSelectSpark([]);
    }, 2000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return detail?.hasSportBook &&
    detail?.premiumFancy == "on" &&
    data?.length > 0 ? (
    <>
      <div
        style={{ paddingBottom: ".1rem" }}
        className={`sports-tab-panel px-2 fancy-sports-inner-tab position-relative `}
      >
        <div
          className="fancy-header-new"
          style={{ height: "2.142857142857143rem" }}
        >
          <div onClick={() => setSelectSport("all")}>
            <h3 className={selectSport == "all" ? "active" : ""}>All</h3>
          </div>
          <div onClick={() => setSelectSport("Popular")}>
            <h3 className={selectSport == "Popular" ? "active" : ""}>
              Popular
            </h3>
          </div>
          <div onClick={() => setSelectSport("Match")}>
            <h3 className={selectSport == "Match" ? "active" : ""}>Match</h3>
          </div>
          <div onClick={() => setSelectSport("Innings")}>
            <h3 className={selectSport == "Innings" ? "active" : ""}>
              Innings
            </h3>
          </div>
          <div onClick={() => setSelectSport("Over")}>
            <h3 className={selectSport == "Over" ? "active" : ""}>Over</h3>
          </div>
          <div onClick={() => setSelectSport("Batsman")}>
            <h3 className={selectSport == "Batsman" ? "active" : ""}>
              Batsman
            </h3>
          </div>
        </div>
        <Button
          style={{ marginRight: "0", padding: "4px", paddingRight: "10px" }}
          className="position-absolute end-0 top-0 info-btn border-0"
          onClick={() => handleShowInfo()}
        >
          <BsFillInfoCircleFill />
        </Button>
      </div>
      <SportBookMatchInner
        updatedData={updatedData}
        parly={parly}
        setSelectSpark={setSelectSpark}
        scrollHeight={scrollHeight}
        myRef={myRef}
        scrollId={scrollId}
        betSlipObject={betSlipObject}
        handelChange={handelChange}
        setSelected={setSelected}
        selectSpark={selectSpark}
        position={position}
        showBetDialog={showBetDialog}
        selected={selected}
        sportBookCheck={sportBookCheck}
        parlyBet={parlyBet}
        detail={detail}
        addToFavioute={addToFavioute}
      />
    </>
  ) : (
    detail?.hasSportBook && detail?.premiumFancy == "on" && (
      <div
        class="py-2 px-3 bg-white"
        style={{
          borderBottomLeftRadius: "7px",
          borderBottomRightRadius: "7px",
        }}
      >
        Markets Mapping…
      </div>
    )
  );
};

export default SportsBook;
