import React, { useContext, useEffect } from "react";
import BetSlipContext from "../context/BetSlipContext";
import { RxCross2 } from "react-icons/rx";
import OutsideClickHandler from "react-outside-click-handler";
import { Button } from "react-bootstrap";
import { BiErrorCircle } from "react-icons/bi";
import { AiOutlineCheckCircle } from "react-icons/ai";

const Message = () => {
  const { setMessage, message } = useContext(BetSlipContext);
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setMessage({ ...message, status: false });
      }}
    >
      <div
        className={`message ${
          message?.errorType == "Error" ||
          message?.errorType == "unMatched" ||
          message?.errorType == "P2P"
            ? "error"
            : "success"
        }`}
      >
        <div
          className="market-title"
          style={{ padding: "7px", paddingTop: "3px" }}
        >
          <h4
            style={{ fontSize: "13px", color: "white" }}
            className="d-flex align-items-center justify-content-center msgbox-w"
          >
            {message?.errorType == "Error" ||
            message?.errorType == "unMatched" ||
            message?.errorType == "P2P" ? (
              <BiErrorCircle size={24} style={{ marginRight: "4px" }} />
            ) : (
              <AiOutlineCheckCircle size={24} style={{ marginRight: "4px" }} />
            )}{" "}
            {message?.errorType == "P2P"
              ? "Error"
              : message?.errorType == "P2P-Success" ||
                message?.errorType == "sportBookParly"
              ? `Bet Matched`
              : `Bet ${message?.errorType}`}
          </h4>
          <Button
            onClick={() => setMessage({ ...message, status: false })}
            className="border-0 abc text-white position-absolute end-0 top-0 pt-1 fs-4 bg-transparent border-0 "
          >
            <RxCross2 />
          </Button>
        </div>
        <div className="messageBox">
          {message?.errorType == "Error" ||
          message?.errorType == "unMatched" ||
          message?.errorType == "P2P" ||
          message?.errorType == "P2P-Success" ||
          message?.errorType == "sportBookParly" ? (
            <div className="inner-messageBox placed-bet">
              {message?.errorType == "sportBookParly" ? (
                <p
                  className="m-0"
                  style={{ fontSize: "15px", padding: "5px 0" }}
                >
                  Total Stake
                  <span style={{ fontWeight: "600", margin: "0 6px" }}>
                   PBU {message?.stake}
                  </span>
                  Max Profit
                  {
                    <span style={{ fontWeight: "600", margin: "0 6px" }}>
                      {message?.totalOdds > 0 && message?.stake > 0
                        ? ((message?.totalOdds - 1) * message?.stake)?.toFixed(
                            2
                          )
                        : 0}
                    </span>
                  }
                </p>
              ) : (
                <p>{message?.message}</p>
              )}
            </div>
          ) : (
            <div className="inner-messageBox placed-bet">
              {message?.betPlaceType == "sportBook" ? (
                ""
              ) : (
                <span
                  className={
                    message?.betType == "No" || message?.betType == "Yes"
                      ? message?.betType == "No"
                        ? "lay"
                        : "back"
                      : message?.betType
                  }
                >
                  {message?.betType}
                </span>
              )}
              <span className="line">
                {message?.teamName || message?.object?.runnerName}
              </span>
              <span className="line">PBU {message?.bidPrice}</span>
              <span>{message?.odds}</span>
            </div>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default Message;
