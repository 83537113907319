import React from "react";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import LayoutComponent from "./Layout";
import { RxCross2 } from "react-icons/rx";

const SidebarLayout = ({ heading, children }) => {
  const navigate = useNavigate();
  return (
    <div>
      <LayoutComponent visibilityType={true}>
        <div className="balance-label position-relative">
          {heading}
          <button className="bg-transparent border-0 text-white position-absolute end-0 pt-0">
            <RxCross2  className="fs-3" style={{fontWeight:"800"}} onClick={() => navigate("/")} />
          </button>
        </div>
        {children}
      </LayoutComponent>
    </div>
  );
};

export default SidebarLayout;
